import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import Card, { CardType } from "./Card";
import { CardListWrapper } from "./styles";

const CardList = ({ cards }) => {
  const listEl = useRef(null);
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const onScroll = e => {
      setScrollTop(e.target.documentElement.scrollTop + window.innerHeight);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  useEffect(() => {
    listEl.current.childNodes.forEach(c => {
      if (scrollTop >= c.offsetTop) {
        c.classList.add("active");
      } else {
        c.classList.remove("active");
      }
    });
  }, [scrollTop, listEl]);

  useEffect(() => {
    setScrollTop(window.innerHeight);
  }, []);

  return (
    <CardListWrapper ref={listEl}>
      {cards.map(card => (
        <Card key={card.id} card={card} />
      ))}
    </CardListWrapper>
  );
};

CardList.propTypes = {
  cards: PropTypes.arrayOf(CardType).isRequired
};

export default CardList;
