import Img from "gatsby-image";
import styled from "styled-components";

export const LinkWrapper = styled.div`
  margin: 20px 0;
  background-color: ${props => props.theme.colors.white};
  opacity: 0;
  transition: opacity 1.5s, transform 1s, background-color 1s;

  &.active {
    background-color: transparent;
    transform: translateY(-50px);
    opacity: 1;
  }

  :hover {
    background-color: ${props => props.theme.colors.white};
    transition: background-color 0.5s;
  }

  @media (max-width: ${props => `${props.theme.breakpoints.s}px`}) {
    background-color: ${props => props.theme.colors.white};
    &.active {
      background-color: ${props => props.theme.colors.white};
    }
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  @media (max-width: ${props => `${props.theme.breakpoints.s}px`}) {
    flex-direction: column;
  }
`;

export const Intro = styled.div`
  flex: 1;
  padding: 10px 40px 10px 45px;
`;

export const Title = styled.h4`
  margin: 0;
`;

export const Date = styled.h6`
  margin: 5px 0;
  color: ${props => props.theme.colors.midGrey};
  font-weight: lighter;
`;

export const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${props => props.backgroundColour};

  img {
    width: 100%;
    height: 100%;
    object-fit: contain !important;
  }

  @media (max-width: ${props => `${props.theme.breakpoints.s}px`}) {
    flex-grow: 5;
  }
`;

export const Image = styled(Img)`
  width: 100%;
  height: 100%;
  overflow: visible;
`;

export const Video = styled.img`
  width: 100%;
  height: 100%;
  overflow: visible;
`;

export const Description = styled.p`
  margin-top: 30px;
  color: ${props => props.theme.colors.midGrey};
  font-size: ${props => props.theme.fontSize.xs};
`;
