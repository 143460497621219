import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import {
  CardWrapper,
  Date,
  Description,
  Image,
  ImageWrapper,
  Intro,
  LinkWrapper,
  Title,
  Video
} from "./styles";

const Card = ({ card }) => {
  const {
    id,
    slug,
    title,
    date,
    description,
    image,
    imageColour,
    videoURL
  } = card;
  return (
    <LinkWrapper key={id}>
      <Link to={slug}>
        <CardWrapper>
          <Intro>
            <Title>{title}</Title>
            <Date>{date}</Date>
            <Description>{description}</Description>
          </Intro>
          <ImageWrapper backgroundColour={imageColour}>
            {videoURL && <Video src={videoURL} />}
            {!videoURL && <Image fluid={image} />}
          </ImageWrapper>
        </CardWrapper>
      </Link>
    </LinkWrapper>
  );
};

export const CardType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  imageColour: PropTypes.string.isRequired,
  videoURL: PropTypes.string
});

Card.propTypes = { card: CardType };

export default Card;
