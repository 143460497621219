import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import CardList from "../components/CardList";
import Layout from "../components/Layout";

const Work = ({ data }) => {
  const cards = data.allMarkdownRemark.edges.map(e => {
    return {
      id: e.node.id,
      slug: e.node.frontmatter.slug,
      title: e.node.frontmatter.title,
      description: e.node.frontmatter.description,
      date: e.node.frontmatter.date,
      tech: e.node.frontmatter.tech,
      image: e.node.frontmatter.featuredImage.childImageSharp.fluid,
      imageColour: e.node.frontmatter.imageColour,
      videoURL:
        e.node.frontmatter.featuredVideo &&
        e.node.frontmatter.featuredVideo.publicURL
    };
  });

  return (
    <Layout>
      <CardList cards={cards} />
    </Layout>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM, YYYY")
            slug
            description
            tech
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            imageColour
            featuredVideo {
              publicURL
            }
          }
        }
      }
    }
  }
`;

Work.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            id: PropTypes.string.isRequired,
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
              slug: PropTypes.string.isRequired,
              date: PropTypes.string.isRequired,
              description: PropTypes.string.isRequired,
              tech: PropTypes.string.isRequired,
              featuredImage: PropTypes.object,
              imageColour: PropTypes.string.isRequired,
              featuredVideo: PropTypes.object
            })
          })
        }).isRequired
      )
    })
  })
};

export default Work;
